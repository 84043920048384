import React from "react";
import "./Services.scss";
import ServicesMainUI from "../../components/ServicesMainUI/ServicesMainUI";

const Services = () => {
  return (
    <div>
      <ServicesMainUI />
    </div>
  );
};

export default Services;
