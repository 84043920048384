import React from "react";
import "./Contacts.scss";
// import Contact from "../../components/Contacts/Contacts";
import Contact from "./../../components/Contact Us/Contact";

const ContactsPage = () => {
  return <Contact />;
};
// refs/remotes/origin/main

const Contacts = () => {
  return <Contact />;
};

export default ContactsPage;
