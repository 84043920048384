import React from "react";

import AboutPageUI from "../../components/AboutpageUI/AboutPageUI";
import NavBarMain from "../../components/NavbarMain/NavbarMain";

const About = () => {
  return (
    <div>
      <AboutPageUI />
    </div>
  );
};

export default About;
